var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "col-12" }, [
      _c("div", {
        staticClass: "notice-title",
        domProps: { innerHTML: _vm._s(_vm.data.noticeTitle) },
      }),
      _c("div", {
        staticClass: "notice-content",
        domProps: { innerHTML: _vm._s(_vm.data.noticeContents) },
      }),
    ]),
    _c("div", { staticClass: "popup-bottom-bar" }, [
      _c("div", { staticClass: "popup-bottom-bar-btngroup" }),
      _c(
        "div",
        { staticClass: "popup-bottom-bar-close" },
        [
          _c("q-btn", {
            attrs: { flat: "", color: "gray", icon: "arrow_back" },
            on: { click: _vm.closePopUps },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }