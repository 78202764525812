<template>
  <div>
    <div class="col-12">
      <div class="notice-title" v-html="data.noticeTitle"></div>
      <div class="notice-content" v-html="data.noticeContents"></div>
    </div>
    <div class="popup-bottom-bar">
      <div class="popup-bottom-bar-btngroup">
      </div>
      <div class="popup-bottom-bar-close">
        <q-btn flat color="gray" icon="arrow_back" @click="closePopUps" />
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'user-pop',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          type: 'single',
          plantCd: '',
        }
      },
    },
  },
  data() {
    return {
      detailUrl: '',
      data: {
        noticeContents: '',
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      this.detailUrl = selectConfig.sys.notice.get.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.$format(this.detailUrl, this.popupParam.sysNoticeId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data = _result.data;
      },
      () => {
      });
    },
    closePopUps() {
      this.$emit('closePopup')
    },
  }
};
</script>
<style lang="scss">
.notice-content {
  padding: 10px 20px;
}
.notice-title {
  font-weight: 600;
  font-size: 1.1em;
  padding: 10px 20px;
}
</style>
